export default function Landing(){
    return <div className="landing">
        {/* top bar */}
        <div className="top-bar">
            {/*left stuff*/}
            <div className="logo">
            <img src="https://i.imgur.com/A56p2hN.png" alt="logo" />
            <div className="logo-text">Instant Legal</div>
            </div>

            {/*center stuff*/}
            <div>
            </div>

            {/*right stuff*/}
            <div className="right-stuff">
                <a href="/app/login">Login</a>
                <a href="/app/signup">Sign Up</a>
        </div>
        </div>

        {/* main content */}

        <div className="main-content">
            <h1>Ask any legal question</h1>
            <h2>We'll answer it within 24 hours</h2>
            <button
                className="btn"
                onClick={() => {
                    window.location.href = '/signup';
                }
                }>
                Join Now
            </button>
        </div>

        <div className="boxes">
            {/*box 1*/}
            <div className="box">
                <h3>98%</h3>
                <p>of our 2000+ users recommend us</p>
            </div>

            {/*box 2*/}
            <div className="box">
                <h3>£50</h3>
                <p>fixed free, never rent a lawyer by an hour</p>
            </div>

            {/*box 3*/}
            <div className="box">
                <h3>24 hours</h3>
                <p>average time to get an answer</p>
            </div>

        </div>

        {/* explanation */}
        <div className={"explanation"}>
            <h2>How it works?</h2>

            <div className="steps">

            {/*    step 1 */}
                <div className="step">
                    <h3>1</h3>
                    <p>Ask your question</p>
                </div>

            {/*    step 2 */}
                <div className="step">
                    <h3>2</h3>
                    <p>Our team of lawyers will answer it</p>
                </div>

                {/*    step 3 */}
                <div className="step">
                    <h3>3</h3>
                    <p>Get your answer within 24 hours</p>
                </div>


            </div>


        </div>
    </div>
}
