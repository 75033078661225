import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, addDoc, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { app } from '../../firebase';
import { arrayUnion } from 'firebase/firestore';
import { Input, Button, List, message, Modal, Layout, Menu } from 'antd';
import { CheckOutlined, PlusCircleOutlined } from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

const auth = getAuth(app);
const db = getFirestore(app);

const App = () => {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [question, setQuestion] = useState('');
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [creditCard, setCreditCard] = useState('');
    const [answer, setAnswer] = useState('');

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                checkAdmin(user.uid);
                fetchQuestions(user.uid);
            } else {
                window.location.href = '/app/login';
            }
        });
    }, []);

    const checkAdmin = async (userId) => {
        const adminUsers = ['RlVtfnqDtSeeEWbm5BO63fwt02q1']; // Example admin user IDs
        const isAdmin = adminUsers.includes(userId);
        setIsAdmin(isAdmin);
        return isAdmin;
    };

    const fetchQuestions = async (userId) => {
        let q;
        const isAdmin = await checkAdmin(userId);
        if (isAdmin) {
            q = query(collection(db, 'questions')); // Fetch all questions if the user is an admin
        } else {
            q = query(collection(db, 'questions'), where('userId', '==', userId)); // Fetch only the user's questions
        }
        const querySnapshot = await getDocs(q);
        const questionsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setQuestions(questionsList);
    };

    const handleAskQuestion = async () => {
        if (!question) {
            message.error('Please enter a question.');
            return;
        }
        setIsModalVisible(true);
    };

    const handlePayment = async () => {
        if (!creditCard) {
            message.error('Please enter credit card details.');
            return;
        }
        message.success('Payment successful!');
        setIsModalVisible(false);

        try {
            const docRef = await addDoc(collection(db, 'questions'), {
                userId: user.uid,
                question,
                createdAt: new Date(),
                answers: [] // Initialize answers as an empty array
            });
            setQuestions([...questions, { id: docRef.id, question, createdAt: new Date(), answers: [] }]);
            setQuestion('');
        } catch (error) {
            console.error('Error adding document: ', error);
            message.error('Failed to upload question.');
        }
    };
    const handleAnswerQuestion = async () => {
        if (!answer) {
            message.error('Please enter an answer.');
            return;
        }

        try {
            const questionRef = doc(db, 'questions', selectedQuestion.id);
            await updateDoc(questionRef, {
                answers: arrayUnion({
                    answer,
                    answeredAt: new Date(),
                    answeredBy: user.uid
                })
            });
            setQuestions(questions.map(q => q.id === selectedQuestion.id ? { ...q, answers: [...q.answers, { answer, answeredAt: new Date(), answeredBy: user.uid }] } : q));
            setAnswer('');
        } catch (error) {
            console.error('Error updating document: ', error);
            message.error('Failed to upload answer.');
        }
    };

    return (
        <Layout id="app">
            <Sider width={300} className="site-layout-background">
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['new']}
                    style={{ height: '100%', borderRight: 0 }}
                >
                    <Menu.Item key="new" onClick={() => setSelectedQuestion(null)} style={{ marginBottom: '10px' }}>
                        <PlusCircleOutlined style={{ color: 'blue', marginRight: '10px' }} />
                        New Chat
                    </Menu.Item>
                    {questions.map((item, index) => (
                        <Menu.Item key={index} onClick={() => setSelectedQuestion(item)}>
                            {item.answers && item.answers.length > 0 ? <CheckOutlined style={{ color: 'green', marginRight: '10px' }} /> : null}
                            {item.question}
                        </Menu.Item>
                    ))}
                </Menu>
            </Sider>
            <Layout>
                <Content id="app-content" style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                    <div className="site-layout-content">
                        <div className="chat-container">
                            <div className="chat-box">
                                {selectedQuestion ? (
                                    <List
                                        bordered
                                        dataSource={[selectedQuestion]}
                                        renderItem={item => (
                                            <List.Item>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                                    <div style={{ background: '#e7f5ff', padding: '10px', borderRadius: '10px', marginBottom: '10px', width: '100%' }}>
                                                        <strong>Question:</strong> {item.question}
                                                        <div style={{ fontSize: '0.8em', color: '#888' }}>Asked by: {user.email}</div>
                                                        <div style={{ fontSize: '0.8em', color: '#888' }}>Asked at: {item.createdAt.toDate().toLocaleString()}</div>
                                                    </div>
                                                    {item.answers && item.answers.map((ans, index) => (
                                                        <div key={index} style={{ background: '#ebfbee', padding: '10px', borderRadius: '10px', width: '100%', alignSelf: 'flex-end', marginBottom: '10px' }}>
                                                            <strong>Answer:</strong> {ans.answer}
                                                            <div style={{ fontSize: '0.8em', color: '#888' }}>Answered by Nicholls & Nicholls team</div>
                                                            <div style={{ fontSize: '0.8em', color: '#888' }}>Answered at: {ans.answeredAt.toDate().toLocaleString()}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                ) : (
                                    <div>Please select a question or start a new chat.</div>
                                )}
                            </div>
                            <div className="input-box">
                                {!isAdmin ? (
                                    <>
                                        <Input.TextArea
                                            value={question}
                                            onChange={(e) => setQuestion(e.target.value)}
                                            placeholder="Type your question here..."
                                            rows={4}
                                        />
                                        <Button type="primary" onClick={handleAskQuestion}>
                                            Ask Question
                                        </Button>
                                    </>
                                ) : (
                                    selectedQuestion && (
                                        <>
                                            <Input.TextArea
                                                value={answer}
                                                onChange={(e) => setAnswer(e.target.value)}
                                                placeholder="Type your answer here..."
                                                rows={4}
                                            />
                                            <Button type="primary" onClick={handleAnswerQuestion}>
                                                Answer Question
                                            </Button>
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
            <Modal
                title="Payment"
                visible={isModalVisible}
                onOk={handlePayment}
                onCancel={() => setIsModalVisible(false)}
            >
                <Input
                    placeholder="Credit Card Number"
                    value={creditCard}
                    onChange={(e) => setCreditCard(e.target.value)}
                />
            </Modal>
        </Layout>
    );
};

export default App;
