import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage, ref } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyC5Xd2jvHZAfC709yeAZgEXL5lIbJ3bYFQ",
    authDomain: "instantlegal-bc691.firebaseapp.com",
    projectId: "instantlegal-bc691",
    storageBucket: "instantlegal-bc691.appspot.com",
    messagingSenderId: "1011558194537",
    appId: "1:1011558194537:web:7c1190fe9e51e4625cf1f8",
    measurementId: "G-P71W5YKWBW"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
