import React, { useEffect } from 'react';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        const logout = async () => {
            await signOut(auth);
            navigate('/app/login');
        };

        logout();
    }, [navigate]);

    return null;
}
