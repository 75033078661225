import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import Login from './Components/Login/Login';
import Logout from './Components/Login/Logout';
import Landing from './Components/Landing/Landing';
import SignUpComponent from './Components/SignUp/SignUp';
import App from './Components/App/App';

import app from './firebase';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Landing />,
    },
    {
        path: "/app",
        element: <App />,
    },
    {
        path: "/app/login",
        element: <Login />
    },
    {
      path: "/app/logout",
      element: <Logout />
    },
    {
        path: "/app/signup",
        element: <SignUpComponent />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
