import React, { Component } from 'react';
import { Button, Checkbox, Input, Upload, message } from "antd";
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { app } from '../../firebase';
import { getDownloadURL } from "firebase/storage";

class SignUpComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            imageUrl: null,
            loading: false,
            userPreSignUpUuid: this.uuidv4(),
        }
    }

    uuidv4() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }

    beforeUpload(file) {
        const isImage = file.type.indexOf('image/') === 0;
        if (!isImage) {
            message.error('You can only upload image files!');
        }
        return isImage;
    };

    async customRequest(event) {
        const { file } = event;
        const storage = getStorage(app);
        const storageRef = ref(storage, `${this.state.userPreSignUpUuid}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({ loading: true });
            },
            (error) => {
                message.error('Upload failed.');
                this.setState({ loading: false });
            },
            async () => {
                try {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    this.setState({ imageUrl: downloadURL, loading: false });
                    message.success('Image uploaded successfully!');
                } catch (error) {
                    message.error('Failed to get download URL.');
                    this.setState({ loading: false });
                }
            }
        );
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { email, password, userPreSignUpUuid } = this.state;
        const auth = getAuth(app);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await updateProfile(user, { displayName: userPreSignUpUuid });
            await sendEmailVerification(user);

            window.location.href = '/app/login';
        } catch (error) {
            console.error(error.code, error.message);
            message.error(error.message);
        }
    }

    render(){
        const { email, password, imageUrl, loading } = this.state;
        return (
            <div className="signup-container">
                <h1>Sign Up</h1>
                <form onSubmit={this.handleSubmit} className="signup-form">
                    <div>
                        <div className="email">
                            <label>e-mail</label>
                            <Input name="email" value={email} onChange={this.handleChange} required />
                        </div>
                        <div className="password">
                            <label>password</label>
                            <Input name="password" value={password} onChange={this.handleChange} type="password" required />
                        </div>

                        <div>
                            <label>Upload Photo ID</label>
                            <Upload
                                name="photoid"
                                listType="picture-card"
                                className="image-upload"
                                showUploadList={false}
                                beforeUpload={this.beforeUpload}
                                customRequest={(event) => this.customRequest(event)}
                            >
                                {imageUrl ? (
                                    <img src={imageUrl} alt="Uploaded Image" />
                                ) : (
                                    <div>
                                        <p>Click to upload</p>
                                    </div>
                                )}
                                {loading && <div>Uploading...</div>}
                            </Upload>
                        </div>

                        <div className="terms-and-conditions">
                            <Checkbox required />
                            <label>agree to terms and conditions</label>
                        </div>
                    </div>

                    <Button type="primary" htmlType="submit">
                        Sign Up Now
                    </Button>

                    <a href="/app/login">Already have an account? Login here</a>
                </form>
            </div>
        );
    }
}

export default SignUpComponent;
